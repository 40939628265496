// import logo from './logo.svg';
import './App.css';
import img1 from '././img/1.png'
import img2 from '././img/2.png'
import img2_2 from '././img/2_2.png'
import img4 from '././img/4.png'
import img5 from '././img/5.png'
import img6 from '././img/6.png'

function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <h3>石狮市第二实验幼儿园安全信息</h3>
      <div>
        <p>餐饮服务食品安全等级公示栏</p>
        <img src={img5} alt="img5" className="Img" />
        <p>食品经营许可证</p>
        <img src={img2} alt="img2" className="Img" />
        <img src={img2_2} alt="img2_2" className="Img" />
        <p>员工健康证</p>
        <img src={img1} alt="error" className="Img" />
        <p>石狮市食品药品日常监督检查记录表</p>
        <img src={img6} alt="img6" className="Img" />
        <p>食品安全承诺书</p>
        <img src={img4} alt="img4" className="Img" />

      </div>

    </div>
  );
}

export default App;